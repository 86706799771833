import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes                                    from 'prop-types';

// import components
import ShareBox  from './index';
import ShareIcon from '../icons/ShareIcon';

const ShareAction = memo( ( { wrapperClass, staticClass, activeClass, shareBoxPosition, shareIconClass, postTitle, postUrl } ) => {
  // define the component state
  const [shareIsVisible, setShareIsVisible] = useState( false );

  // define the component ref
  const node = useRef();

  /**
   * Processing when click outside the component
   */
  const handleClickOutside = e => {
    if ( node.current.contains( e.target ) ) {
      return;
    }

    setShareIsVisible( false );
  };

  /**
   * Add/remove the event listener
   */
  useEffect( () => {
    if ( shareIsVisible ) {
      document.addEventListener( 'mousedown', handleClickOutside );
    } else {
      document.removeEventListener( 'mousedown', handleClickOutside );
    }

    return () => {
      document.removeEventListener( 'mousedown', handleClickOutside );
    }
  }, [shareIsVisible] );

  /**
   * Return
   */
  return (
    <div className={ wrapperClass }>
      <ShareBox isVisible={ shareIsVisible }
                position={ shareBoxPosition }
                shareCallback={ () => setShareIsVisible( false ) }
                title={ postTitle }
                url={ postUrl } />
      <div className={ shareIsVisible ? activeClass : staticClass }
           ref={ node }
           role="presentation"
           onClick={ () => setShareIsVisible( v => !v ) }>
        <ShareIcon className={ shareIconClass } />
      </div>
    </div>
  );
});

ShareAction.defaultProps = {
  wrapperClass: '',
  staticClass: '',
  activeClass: '',
  shareBoxPosition: 'right',
  shareIconClass: '',
  postTitle: '',
  postUrl: ''
};

ShareAction.propTypes = {
  wrapperClass: PropTypes.string,
  staticClass: PropTypes.string,
  activeClass: PropTypes.string,
  shareBoxPosition: PropTypes.string.isRequired,
  shareIconClass: PropTypes.string,
  postTitle: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired
};

export default ShareAction;