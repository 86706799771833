import styled from 'styled-components';

export const ShareBoxElement = styled.div`
  --width: 240px;

  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--width);
  height: 60px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  padding: 10px 20px;
  background-color: var(--white);
  box-shadow: 0 0 30px rgba(var(--black--rgb), .2);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 1;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--white);
    z-index: 1;
  }
  
  &.center {
    left: calc(50% - (var(--width) / 2));
    
    &::after {
      left: calc(50% - 10px);
    }
  }
  
  &.left {
    
  }
  
  &.right {
    left: -190px;
    
    &::after {
      right: 20px;
    }
  }
  
  &.is-visible {
    top: -75px;
    opacity: 1;
    visibility: visible;
  }
  
  .share-item {
    --dimensions: 40px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    border: 2px solid var(--theme-dark-purple);
    border-radius: 50%;
    
    &:hover {
      cursor: pointer;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1024px) {
    --width: 200px;
    
    height: 45px;
    
    &.right {
      left: -150px;
    }
    
    &.is-visible {
      top: -60px;
    }
  
    .share-item {
      --dimensions: 30px;
      
      svg {
        width: 65%;
        height: 65%;
      }
    }
  }
`;