import React, { memo } from 'react';
import { Link }        from 'gatsby';
import PropTypes       from 'prop-types';

// import constants
import { BACKEND_URL } from '../../../constants';

// import styles
import { PostPreviewElement } from '../../../styles/elements/Post/PostPreview';

// import assets
import tempImg from '../../../assets/images/post-preview__img.png';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';
import ShareAction      from '../ShareBox/ShareAction';

const PostPreview = memo( ( { data, additionalClass, dataAttr, dataAttrValue } ) => {
  const {
    postRegularImage, postRegularImageAlt, postRegularImageIncreased, postRegularImageIncreasedAlt, postTitle, postDate,
    postCategory, postShortDescription, postCommentsCount, postCommentsTextSingle, currentLang,
    postCommentsTextMultiple, postSlug, postAuthorData: { name, authorImg, image_alt }
  } = data;

  const regularImage   = postRegularImage !== null ? BACKEND_URL + postRegularImage : tempImg;
  const increasedImage = postRegularImageIncreased !== null ? BACKEND_URL + postRegularImageIncreased : tempImg;
  const commentsText   = postCommentsCount === 1 ? postCommentsTextSingle : postCommentsTextMultiple;
  const postUrl = currentLang === 'de' ? `/${ postSlug }` : `/en/${ postSlug }`;

  return (
    <PostPreviewElement className={ additionalClass } data-category={ dataAttrValue }>
      <div className="image__wrapper">
        <Link className="image__link" to={ postUrl }>
          <img className="image"
               src={ additionalClass === 'half' ? increasedImage : regularImage }
               alt={ additionalClass === 'half' ? postRegularImageIncreasedAlt : postRegularImageAlt } />
        </Link>
      </div>

      <div className="meta">
        <span className="date">{ postDate }</span>
        <ShareAction wrapperClass="actions"
                     staticClass="actions__share"
                     activeClass="actions__share actions__share--is-active"
                     shareBoxPosition="right"
                     shareIconClass="actions__share__icon"
                     postTitle={ postTitle }
                     postUrl={ postUrl } />
      </div>

      <span className="category">{ postCategory }</span>
      <h3 className="title">
        <Link className="title__link" to={ postUrl }>{ postTitle }</Link>
      </h3>
      <p className="description">{ postShortDescription }</p>

      <div className="bottom">
        <div className="author">
          <img className="author__img"
               src={ BACKEND_URL + authorImg }
               alt={ image_alt } />

          <div className="author__meta">
            <span className="author__name">{ name }</span>
            <span className="author__data">{ `${ postCommentsCount } ${ commentsText }` }</span>
          </div>
        </div>

        <Link className="btn" to={ postUrl } title={ postTitle }>
          <ArrowRegularIcon className="btn__arrow" color="#00B7F1" />
        </Link>
      </div>
    </PostPreviewElement>
  )
});

PostPreview.defaultProps = {
  data: {},
  additionalClass: '',
  dataAttr: '',
  dataAttrValue: ''
};

PostPreview.propTypes = {
  data: PropTypes.object.isRequired,
  additionalClass: PropTypes.string,
  dataAttr: PropTypes.string,
  dataAttrValue: PropTypes.string
};

export default PostPreview;