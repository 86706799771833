import styled from 'styled-components';

export const PostPreviewElement = styled.div`
  --post-mr: 40px;

  display: flex;
  flex-direction: column;
  /*
    1/3  - 3 columns per row
    10px - spacing between columns 
  */
  box-sizing: border-box;
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * var(--post-mr));
  margin: 0 var(--post-mr) 60px 0;
  border-radius: 12px;
  padding: 34px 34px 24px 34px;
  box-shadow: 0 0 25px rgba(var(--black--rgb), .2);
  
  &:nth-child(3n) {
    margin-right: 0;
  }
  
  &.half {
    width: calc(50% - (var(--post-mr) / 2));
      
    &:nth-child(2n - 1) {
      margin-right: 0;
    }
    
    .title {
      min-height: 70px;
    }
    
    .description {
      min-height: 100px;
    }
  }
  
  .image {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    transition: 1s transform ease-in-out;
      
    &:hover {
      transform: scale(1.05);
    }
    
    &__wrapper {
      overflow: hidden;
    }
  }
  
  .meta {
    display: flex;
    align-items: center; 
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .date {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
    color: var(--theme-dark-gray);
  }
  
  .actions {
    position: relative;
    
    &__share {
      --dimensions: 40px;
    
      display: flex;  
      align-items: center;
      justify-content: center;
      width: var(--dimensions);
      height: var(--dimensions);
      border-radius: 50%;
      background-color: var(--white);
      transition: .3s all ease-in-out;
      
      &--is-active {
        background-color: var(--theme-dark-purple);
        
        svg {
          path {
            fill: var(--white);
          }
        }
      }
      
      &__icon {
        width: 16px;
        height: 19px; 
        transition: .3s all ease-in-out;
      }
      
      &:hover {
        cursor: pointer;
      }
    }
  }
  
  .category {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
    color: var(--theme-blue);
  }
  
  .title {
    min-height: 110px;
    margin: 0 0 15px 0;
    
    &__link {
      display: block;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.2px;
      color: var(--theme-deep-purple);
      transition: .3s all ease-in-out;
      
      &:hover {
        color: var(--theme-blue);
      }
    }
  }
  
  .description {
    min-height: 120px;
    margin: 0 0 15px 0;
    letter-spacing: 1.25px;
  }
  
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .author {
    display: flex;
    align-items: center;
    
    &__img {
      --dimensions: 65px;
      
      width: var(--dimensions);
      height: var(--dimensions);
      margin-right: 20px;
      border-radius: 50%;
    }
    
    &__meta {
      display: flex;
      flex-direction: column;
    }
    
    &__name {
      font-weight: 400;
      color: var(--theme-dark-gray);
    }
    
    &__data {
      font-size: 14px;
      line-height: 25px;
      font-weight: 400;
      color: var(--theme-dark-gray);
    }
  }
  
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border: 2px solid var(--theme-blue);
    border-radius: 50%;
    padding: 0;
    background-color: var(--white);
    transition: .3s all ease-in-out;
    
    &:hover {
      transform: rotate(1turn);
      cursor: pointer;
    }
    
    &__arrow {
      width: 20px;
      height: 20px;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    --post-mr: 20px;
  
    margin-bottom: 50px;
    padding: 28px 26px 22px 26px;
    
    .description {
      line-height: 27px;
    }
    
    .author {
      &__img {
        margin-right: 10px;
      }
    }
  }
  
  @media (max-width: 1280px) {
    --post-mr: 15px;
    
    padding: 20px 19px 18px 19px;
    
    &.half {
      .title {
        min-height: 60px;
      }
    }
    
    .title {
      min-height: 90px;
      margin-bottom: 5px;
      
      &__link {
        font-size: 22px;
        line-height: 26px;
      }
    }
    
    .description {
      min-height: 130px;
      line-height: 22px;
    }
    
    .author {
      &__img {
        --dimensions: 60px;
      }
    
      &__data {
        padding-right: 15px;
        line-height: 16px;
      }
    }
  }
  
  @media (max-width: 1024px) {
    &.half {
      .title {
        min-height: 50px;
      }
    }
    
    .image {
      margin-bottom: 10px;
    }
    
    .meta,
    .category {
      margin-bottom: 0;
    }
    
    .title {
      min-height: 70px;
    
      &__link {
        font-size: 18px;
        line-height: 22px; 
      }
    }
    
    .description {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
    
    .author {
      &__img {
        --dimensions: 50px;
        margin-right: 5px;
      }
      
      &__data {
        padding-right: 0;
      }
    }
  }
  
  @media (max-width: 991px) {
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * var(--post-mr));
    margin-bottom: 40px;
    
    &:nth-child(2n) {
      margin-right: 0;
    }
    
    &:nth-child(3n) {
      margin-right: inherit;
    }
    
    &.search-post-item {
      &.is-hidden {
        display: none;
      }
      
      .title {
        margin: 0 0 15px 0;
      }
    }
    
    &.half {
      width: 100%;
      margin-right: 0;
      
      .description {
        min-height: 60px;
      }
    }
    
    .author {
      &__img {
        margin-right: 10px;
      }
    }
  }
  
  @media (max-width: 480px) {
    --post-mr: 0;
  
    width: 100%;
    
    &.search-post-item {
      .image {
        &__wrapper {
          overflow: initial;
        }
      }
      
      .title,
      .description {
        min-height: auto;
      }
    }
    
    .title {
      min-height: auto;
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 26px;
    }
    
    .description {
      min-height: auto;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
    }
    
    .bottom {
      border-top: 1px solid #707070;
      padding-top: 10px;
    }
  }
  
  @media (max-width: 390px) {
    .btn {
      width: 42px;
      height: 42px;
    }
    
    .author {
      &__name {
        font-size: 14px;
        line-height: 22px;
      }
      
      &__data {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  
  @media (max-width: 375px) {
    .author {
      &__img {
        display: none;
      }
    } 
  }
`;