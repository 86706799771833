import React, { memo, useContext, useState, useEffect } from 'react';
import { graphql, navigate, useStaticQuery }            from 'gatsby';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import style
import { SearchElement } from '../../../styles/elements/Search';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';
import SearchResults    from './SearchResults';

const Search = memo( ( { className } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          search_texts {
            desktop_placeholder
          }
        }
      }
    }
  `);

  // get the app global state
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [resultsIsOpened, setResultsIsOpened] = useState( false );
  const [searchValue, setSearchValue]         = useState( '' );
  const [searchResults, setSearchResults]     = useState( [] );
  const [placeholder, setPlaceholder]         = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { search_texts } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setPlaceholder( search_texts[orderNumber].desktop_placeholder );
    });
  }, [data, defaultLang] );

  /**
   * Processing a form submitting
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    setResultsIsOpened( false );
    navigate( defaultLang === 'de' ? '/search' : '/en/search', { state: { searchResults, searchValue } } );
  };

  /**
   * Processing a field value change
   *
   * @param e | Event
   */
  const handleChange = ( e ) => {
    const value = e.currentTarget.value;
    const requestUrl = defaultLang === 'de'
      ? 'https://monkeybackend.ch/api/blog_new/posts-search-german/?search='
      : 'https://monkeybackend.ch/api/blog_new/posts-search-english/?search=';

    setSearchValue( value );

    fetch( requestUrl + value )
      .then( response => response.json() )
      .then(
        data => {
          const { search_results } = data;
          setSearchResults( search_results );
        },
        error => { console.log( error );
        });

    if ( value !== '' ) {
      setTimeout( () => {
        setResultsIsOpened( true );
      }, 300 );
    } else {
      setResultsIsOpened( false );

      setTimeout( () => {
        setSearchResults( [] );
      }, 300 );
    }
  };

  /**
   * Processing a field value change
   */
  const handleBlur = () => {
    setTimeout( () => {
      setResultsIsOpened( false );
    }, 150 );
  };

  return (
    <SearchElement className={ className }>
      <form className="field-group"
            onSubmit={ ( e ) => handleSubmit( e ) }>
        <input className="field"
               type="text"
               value={ searchValue }
               placeholder={ placeholder }
               onChange={ ( e ) => handleChange( e ) }
               onBlur={ handleBlur } />
        <button className={ resultsIsOpened ? 'btn is-opened' : 'btn' }
                aria-label="Search"
                type="submit">
          <ArrowRegularIcon />
        </button>
      </form>
      <SearchResults isOpened={ resultsIsOpened }
                     posts={ searchResults }
                     value={ searchValue } />
    </SearchElement>
  )
});

Search.defaultProps = {
  className: ''
};

Search.propTypes = {
  className: PropTypes.string
};

export default Search;