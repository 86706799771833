import styled from 'styled-components';

export const SearchElement = styled.div`
  position: relative;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 13px 20px;
  box-shadow: 0 0 30px rgba(var(--black--rgb), .2);
  
  .field {
    width: 100%;
    height: 60px;
    border: 2px solid var(--theme-deep-purple);
    border-radius: 38px;
    padding: 21px 27px;
    font-weight: 400;
    letter-spacing: 1.4px;
    color: var(--theme-deep-purple);
    
    &::placeholder {
      color: var(--theme-dark-gray);
    }
    
    &:focus {
      outline: none;
    }
  
    &-group {
      position: relative;
    }
  }
  
  .btn {
    --dimensions: 30px;
  
    position: absolute;
    top: calc(50% - (var(--dimensions) / 2));
    right: 10px;
    width: var(--dimensions);
    height: var(--dimensions);
    border: 2px solid var(--theme-deep-purple);
    border-radius: 50%;
    padding: 0;
    background-color: var(--white);
    transition: .3s all ease-in-out;    
    
    &:hover {
      transform: rotate(1turn);
      cursor: pointer;
    }
    
    &.is-opened {
      transform: rotate(1.25turn);
    }
    
    svg {
      width: 12px;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    margin-bottom: 22px;
  }
  
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  
    .field {
      height: 48px;
    }
  }
  
  @media (max-width: 991px) {
    display: none;
    
    &.search-page {
      display: block;
    }
  }
`;