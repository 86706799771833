import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const ShareIcon = memo( ( { className, color, onClick } ) => {
  /**
   * Processing click action.
   * Passing data to the received function
   */
  const handleClick = () => {
    if ( onClick !== undefined ) onClick();
  };

  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="15.727"
         height="19.998"
         viewBox="0 0 15.727 19.998"
         onClick={ handleClick }>
      <path d="M11.5,13.09a.706.706,0,0,0,.7-.691V3.3L12.148,1.97l.594.629,1.347,1.435a.647.647,0,0,0,.478.213A.622.622,0,0,0,15,3.157L12.006.269a.662.662,0,0,0-1.019,0L7.992,3.157a.627.627,0,0,0-.213.461.612.612,0,0,0,.638.629.666.666,0,0,0,.487-.213L10.252,2.6l.594-.629L10.792,3.3v9.1A.713.713,0,0,0,11.5,13.09ZM6.415,20.028H16.578a2.447,2.447,0,0,0,2.782-2.747V8.438a2.447,2.447,0,0,0-2.782-2.747H14.106V7.118h2.446a1.253,1.253,0,0,1,1.382,1.4V17.2a1.253,1.253,0,0,1-1.382,1.4H6.433A1.243,1.243,0,0,1,5.06,17.2V8.518a1.243,1.243,0,0,1,1.373-1.4H8.887V5.691H6.415A2.447,2.447,0,0,0,3.633,8.438v8.843A2.447,2.447,0,0,0,6.415,20.028Z"
            transform="translate(-3.633 -0.029)"
            fill={ color } />
    </svg>
  )
});

ShareIcon.defaultProps = {
  className: '',
  color: '#0E1237',
  onClick: () => {}
};

ShareIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default ShareIcon;