import React, { memo }             from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes                   from 'prop-types';

// import styles
import { ShareBoxElement } from './styles';

// import components
import LinkedinIcon from '../icons/LinkedinIcon';
import TwitterIcon  from '../icons/TwitterIcon';
import FacebookIcon from '../icons/FacebookIcon';
import WhatsupIcon  from '../icons/WhatsupIcon';

const ShareBox = memo( ( { isVisible, position, shareCallback, title, url } ) => {
  const data = useStaticQuery( graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const iconColor = '#431E75';
  const postLink = data.site.siteMetadata.siteUrl + url;

  /**
   * Processing sharing action
   *
   * @param e | Event
   * @param type | string
   */
  const handleShare = ( e, type ) => {
    e.preventDefault();

    if ( shareCallback !== undefined ) {
      shareCallback();
    }

    if ( type === 'linkedin' ) {
      window.open( 'http://www.linkedin.com/shareArticle?mini=true&url=' + postLink + '&title=' + title, '', '_blank, width=500, height=500, resizable=yes, scrollbars=yes' );
    }

    if ( type === 'twitter' ) {
      window.open( 'https://twitter.com/intent/tweet?url=' + postLink + '&text=' + title, '' , '_blank, width=500, height=500, resizable=yes, scrollbars=yes' );
    }

    if ( type === 'facebook' ) {
      window.open( 'https://www.facebook.com/sharer/sharer.php?u=' + postLink + '&quote=' + title, '' , '_blank, width=500, height=500, resizable=yes, scrollbars=yes' );
    }
  };

  return (
    <ShareBoxElement className={ `share-box ${ position } ${ isVisible ? 'is-visible' : '' }` }>
      <div className="share-item"
           role="presentation"
           onClick={ ( e ) => handleShare( e, 'linkedin' ) }>
        <LinkedinIcon color={ iconColor } />
      </div>

      <div className="share-item"
           role="presentation"
           onClick={ ( e ) => handleShare( e, 'twitter' ) }>
        <TwitterIcon color={ iconColor } />
      </div>

      <div className="share-item"
           role="presentation"
           onClick={ ( e ) => handleShare( e, 'facebook' ) }>
        <FacebookIcon color={ iconColor } />
      </div>

      <a className="share-item"
         href={ `whatsapp://send?text=${ title } ${ postLink }` }
         data-action="share/whatsapp/share"
         target="_blank"
         rel="noreferrer nofollow">
        <WhatsupIcon color={ iconColor } />
      </a>
    </ShareBoxElement>
  );
});

ShareBox.defaultProps = {
  isVisible: false,
  position: 'center', // available values: center, left, right
  shareCallback: () => {},
  title: '',
  url: ''
};

ShareBox.propTypes = {
  isVisible: PropTypes.bool,
  position: PropTypes.string,
  shareCallback: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string.isRequired
};

export default ShareBox;